<template>
  <div>
    <b-navbar variant="darkpink" type="dark" toggleable="fm">

      <b-navbar-brand :to="'/'">
        <img width="200" src="/static/images/logo.png" class="d-inline-block align-content-center" alt="Beki Osborne">
        <!--        Feminist Mentoring-->
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!--      <b-link :to="'Blog'" class="fm-nav-link">Blog</b-link>-->
        <b-link :to="'/feminist-mentoring'" class="fm-nav-link">Feminist Mentoring</b-link>
        <b-link :to="'/contact'" class="fm-nav-link">Contact Us</b-link>

        <b-navbar-nav class="ms-auto">

          <b-nav-item-dropdown class="fm-nav-link user-dropdown notification-nav"
                               v-if="$store.state.isAuthenticated && !$store.state.isAdmin" text="Notifications" right>
            <template slot="button-content" class="fm-nav-link">Notifications
              <b-badge id="notification-count" variant="light">{{ notification_count }}</b-badge>
            </template>
            <b-dropdown-item href="#" class="no-notifications" v-if="notification_count === 0">
              <b-list-group-item href="#" class="flex-column align-items-start">
                <p class="mb-1">
                  <i>No notifications pending</i>
                </p>

              </b-list-group-item>
            </b-dropdown-item>


            <b-dropdown-item class="notifications" href="#" v-for="notification in notifications"
                             v-bind:key="notification.id">
              <b-list-group-item href="#" class="flex-column align-items-start" :to="{ name:  notification.url }">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1"><strong>{{ notification.headline }}</strong></h5>
<!--                  <small class="text-muted">2 days ago</small>-->
                </div>

                <p class="mb-1">
                  {{ notification.text }}
                </p>

              </b-list-group-item>
            </b-dropdown-item>


            <!--          <b-dropdown-item href="#" v-if="$store.state.isMentee">-->
            <!--            <b-list-group-item href="#" class="flex-column align-items-start">-->
            <!--              <div class="d-flex w-100 justify-content-between">-->
            <!--                <h5 class="mb-1"><strong>Getting Started Survey</strong></h5>-->
            <!--                <small class="text-muted">3 days ago</small>-->
            <!--              </div>-->

            <!--              <p class="mb-1">-->
            <!--                Complete your first Mentee survey to later help understand and track your progress.-->
            <!--              </p>-->

            <!--            </b-list-group-item>-->
            <!--          </b-dropdown-item>-->

            <!--          <b-dropdown-item href="#" v-if="$store.state.isMentor">-->
            <!--            <b-list-group-item href="#" class="flex-column align-items-start">-->
            <!--              <div class="d-flex w-100 justify-content-between">-->
            <!--                <h5 class="mb-1"><strong>Getting Started Survey</strong></h5>-->
            <!--                <small class="text-muted">3 days ago</small>-->
            <!--              </div>-->

            <!--              <p class="mb-1">-->
            <!--                Complete your first Mentor survey to later help understand and track your progress.-->
            <!--              </p>-->

            <!--            </b-list-group-item>-->
            <!--          </b-dropdown-item>-->
          </b-nav-item-dropdown>

          <!--        <b-link :to="'Home'" class="nav-link">About us</b-link>-->
          <b-link v-if="$store.state.isAuthenticated && !$store.state.isAdmin" :to="'Forum'"
                  class="nav-link fm-nav-link forum-nav">Forum
          </b-link>
          <b-link v-if="$store.state.isMentee" :to="'MenteeHub'" class="nav-link fm-nav-link mentee-nav">Mentee Hub
          </b-link>
          <b-link v-if="$store.state.isMentor" :to="'MentorHub'" class="nav-link fm-nav-link mentor-nav">Mentor Hub
          </b-link>
          <b-link v-if="!$store.state.isAuthenticated" :to="'Login'" class="nav-link fm-nav-link px-2">Participant
            Login
          </b-link>
          <!--        <b-link class="nav-link" :key="$store.state.isAuthenticated" v-if="!$store.state.isAuthenticated" :to="{ name: 'Register' }">Register</b-link>-->
          <!--        <b-link class="nav-link" :key="$store.state.isAuthenticated" v-if="!$store.state.isAuthenticated" :to="'Login'">Login</b-link>-->
          <!--        <b-link :key="$store.state.isAuthenticated" v-if="!$store.state.isAuthenticated"><router-link :to="{ name: 'Login' }">Login</router-link></b-link>-->
          <!--        <b-link :key="$store.state.isAuthenticated" v-if="$store.state.isAuthenticated"><router-link :to="{ path: '/logout'}">Log out</router-link></b-link>-->
          <!--        <li class="nav-link" style="float:right">{{ $store.state.authUser }}</li>-->
          <b-nav-item-dropdown class="user-dropdown fm-nav-link user-nav" v-if="$store.state.isAuthenticated"
                               :text=$store.state.authUser right>
            <b-dropdown-item href="#" :to="{ name: 'Profile' }">Profile</b-dropdown-item>
            <b-dropdown-item href="#" v-if="$store.state.isAdmin" :to="{ name: 'Admin' }">Manage</b-dropdown-item>
            <b-dropdown-item href="#" v-if="$store.state.isAuthenticated" @click.prevent="logout">Logout
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

  </div>
</template>

<script>
import api from "../../api.js"
import jwt_decode from "jwt-decode"
import {logout} from "@/auth";

export default {
  name: 'Navigation',
  data() {
    return {
      notification_count: 0,
      notifications: []
    }
  },
  mounted: function () {
    if (this.$store.state.isAuthenticated) {
      this.getNotifications()
    }
  },
  watch: {
    '$store.state.isAuthenticated': {
      handler: function () {
        if (this.$store.state.isAuthenticated === true) {
          this.getNotifications()
        }
      },
    },
    '$parent.reload_notifications': {
      handler: function () {
        if (this.$parent.reload_notifications === true) {
          this.getNotifications()
          this.$parent.reload_notifications = false;
        }
      },
    }
  },
  methods: {
    getNotifications() {
      api.get("/active_notifications/")
          .then((response) => {
            this.notification_count = response.data.length;
            this.notifications = response.data
          })

    },
    logout,
    login(evt) {
      evt.preventDefault()
      const payload = {
        email: this.form.email,
        password: this.form.password
      }
      api.post(this.$store.state.endpoints.obtainJWT, payload)
          .then((response) => {
            // this.$cookies.set('token', response.data.token);
            const decoded = jwt_decode(response.data.access)
            this.$store.commit("setAuthUser",
                {
                  authUser: decoded.name,
                  isAdmin: decoded.isAdmin,
                  isAuthenticated: true,
                  isMentee: decoded.isMentee,
                  isMentor: decoded.isMentor,
                  access: response.data.access,
                  refresh: response.data.refresh
                })
          })
          .then(() => {
            if (this.$store.state.isMentee) {
              this.$router.push({name: 'MenteeHub'})
            } else if (this.$store.state.isMentor) {
              this.$router.push({path: '/mentorhub'})
            } else if (this.$store.state.isAdmin) {
              this.$router.push({path: '/admin'})
            } else {
              console.log("Error: Unknown condition")
            }
          })
    },
  }

}
</script>
<style lang="css" scoped>


.navbar {
  /*padding: 1rem 0;*/
  padding: 0.5rem 1rem;
}


.bg-darkpink {
  background-color: #012A36 !important;
  /*background-color: #423A4E !important;*/
  /*background-color: #3B413C !important;*/

}

.navbar-brand img {
  padding-left: 10px;
}


.navbar-brand {
  font-size: 23px;
  color: #E66EB2;
  margin-right: 10px;
}

.navbar-brand:hover {
  color: #E66EB2 !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
}

.user-dropdown span.badge-light {
  background-color: palevioletred;
  color: black;
}

.router-link-active {
  color: rgba(255, 255, 255, 0.82);
}

.nav-link:hover, .nav-link:focus, .fm-nav-link:hover, .fm-nav-link:focus, .fm-nav-link.router-link-active {
  color: rgba(243, 39, 154, 0.85) !important;
}

.fm-nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  display: block;
  font-size: 20px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.77);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}


.header-left {
  color: #dd6aab;
  width: 50%;
  text-align: center;
}

.header-left p {
  position: relative;
  top: 10%;
  font-size: 90px;
  font-family: "Brush Script MT";
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;

}

.navbar-collapse a {
  padding-top: 10px;
  padding-bottom: 5px;
}

li.notification-nav a {
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}

.user-nav a {
  padding-top: 10px;
  padding-bottom: 5px;
  color: white;
}

.no-notifications a {
  cursor: default;
}


</style>
