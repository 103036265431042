<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
Blog holding page

  </b-container>
</template>

<script>
import api from "../api.js";

export default {
  name: "Blog",
  data() {
    return {
      auction_id: null,
      title: "",
      enddate: "",
      has_reserve: "",
      highlights: "",
      banner: require('../assets/specs-background.jpg'),
    }
  },
  mounted: function () {
    // const $body = $("body");
    // const scrollClass = "scroll";
    //
    // $(window).on("scroll", () => {
    //   if (this.matchMedia("(min-width: 992px)").matches) {
    //     const scrollY = $(this).scrollTop();
    //     scrollY > 0
    //         ? $body.addClass(scrollClass)
    //         : $body.removeClass(scrollClass);
    //   } else {
    //     $body.removeClass(scrollClass);
    //   }
    // });
    //
    // $(".page-header .nav-link, .navbar-brand").on("click", function(e) {
    //   e.preventDefault();
    //   const href = $(this).attr("href");
    //   $("html, body").animate({
    //     scrollTop: $(href).offset().top - 71
    //   }, 600);
    // });
  },
  methods: {
    getAuction(id) {
      api.get("/auction_full/" + id)
          .then((response) => {
            this.title = response.data.auction.title;
            this.startdate = response.data.auction.startDate;
            this.enddate = response.data.auction.endDate;
            this.has_reserve = response.data.auction.has_reserve;
            this.summary = response.data.car.summary;
            this.make = response.data.car.make;
            this.model = response.data.car.model;
            this.year = response.data.car.year;
            this.interior = response.data.car.interior;
            this.highlights = response.data.auction.highlights;
            this.registration = response.data.car.registration;
            this.gearbox = response.data.car.gearbox;
            this.colour = response.data.car.colour;
            this.engine = response.data.car.engine;
            this.mileage = response.data.car.mileage;
            this.headline_photo = response.data.auction.headline_photo
            this.currentbid = response.data.auction.currentbid
            this.bids = response.data.auction.bidcount
          })
    },
  },
  // computed: {
  //   cssProps() {
  //     return {
  //       '--nav-background': "linear-gradient(rgba(112, 63, 69, 0.8), rgba(112, 63, 69, 0.8)), url(" + this.headline_photo + ") no-repeat 10% 10%"
  //     }
  //   }
  // }
}
</script>

<style scoped>

:root {
  --lightblue: #f6f9fc;
  --red: #d64041;
}

a,
a:hover {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

.bg-lightblue {
  background: var(--lightblue);
}

.bg-red {
  background: var(--red);
}

.text-red {
  color: var(--red);
}

.container-fluid-max {
  max-width: 1440px;
}

.cover {
  background: no-repeat center/cover;
}

.p-15 {
  padding: 15px;
}

/* SCROLL ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.scroll .page-header {
  background: var(--red);
}

.scroll .hero {
  transform: scale(0.98);
}

/* HEADER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.navbar {
  transition: background 0.5s ease-in-out;
}

.navbar {
  padding: 1rem 0;
}

.navbar-toggler {
  /* the variable is inherited from BS4 built-in variables */
  border-color: var(--white);
}

/* BANNER SECTION
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.hero {
  background-attachment: fixed;
  transition: transform 0.5s ease-in-out;
}

.hero::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
      rgba(0, 0, 0, 0.5) 0,
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.1) 100%
  );
}

.hero .container-fluid {
  z-index: 10;
}

/* POPULAR DESTINATIONS SECTION
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.popular-destinations figure {
  margin-bottom: 30px;
}

.popular-destinations figcaption {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.popular-destinations img {
  filter: grayscale(100%) blur(3px);
  transition: transform 0.5s, filter 0.75s;
}

.popular-destinations a:hover img {
  transform: scale(1.25);
  filter: none;
}

/* PAGE FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer .footer-links {
  text-align: right;
}

/* MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* MEDIUM SCREENS */
@media screen and (max-width: 991px) {
  .page-header {
    background: var(--red);
  }
}

/* SMALL SCREENS */
@media screen and (max-width: 767px) {
  .page-footer .footer-child {
    text-align: center;
  }
}

</style>
