<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <div class="image_header"></div>
    <div class="header">
      <h1 class="fm-header-text">Feminist Mentoring</h1>
    </div>
    <b-container fluid="xl">
      <b-row class="pt-4">
        <b-col cols="12" class="intro-text">

    <b-row class="about-text">
      <b-row>
        <b-col cols="12"><p>I offer feminist mentoring programmes to organisations, networks and community groups who seek to holistically develop their members and provide safe spaces for people to explore themselves and the world around them.</p></b-col>
      </b-row>
      <b-row class="mt-3">
      </b-row>
      <b-row class="mt-2">
        <b-card-group>
        <b-card
            border-variant="journey"
            header="Mentoring"
            header-bg-variant="journey"
            header-border-variant="journey"
            header-text-variant="black"
            align="center"
            class="m-2"
        >
          <b-card-text>
            This is a process to allow individuals to develop skills and gain knowledge in areas that interest them with guidance from a mentor who has more experience in that area. Mentees can benefit from learning about the path their mentor has taken to get them to where they are today, and apply this to their own situation to help them to reach their goals.
          </b-card-text>
        </b-card>
        <b-card
            border-variant="journey"
            header="Feminist mentoring"
            header-bg-variant="journey"
            header-border-variant="journey"
            header-text-variant="black"
            align="center"
            class="m-2"
        >
          <b-card-text>
            We explore the power dynamics and structures we work and live in to create a space for individuals to support one another in combatting any oppression that may feel limiting in order for us all to reach our potential. With an intersectional lens, we will value and honour each person’s diverse lived experiences and create mutually beneficial relationships.
          </b-card-text>
        </b-card>
        </b-card-group>
      </b-row>
      <b-row  class="mt-2">
        <div>
        <p><br>Feminist mentoring is for everyone. We reject concepts of a “glass ceiling” and “imposter syndrome”, and any other way society tries to point out that we don’t belong. We challenge patriarchal workplaces, policies and practices that hinder progression.</p>
        <blockquote>
          Thank you for letting me be a part of this mentoring experience. I have learnt a lot about myself and how to support others
          <span>Recent Mentor</span>
        </blockquote>
        </div>
        <p style="text-align: center;">Want to know more about how this could benefit you and the people around you?<br><b-button variant="contact" :to="{ name: 'Contact' }"><b-icon icon="envelope"></b-icon> Get in Touch</b-button></p>
        <br><br><br><br>
        <b-img class="about-image m-auto" width="166" src="/static/images/emcc-uk-member-logo-transparent.png" alt="EMCC UK Member Logo" style="width:166px;height:auto"></b-img>
        <p style="text-align:center;"><br>I am a member of EMCC UK and subscribe to the <a class="fm-link" href="https://www.emccglobal.org/leadership-development/ethics/" target="_blank">EMCC Global Code of Ethics</a></p>


      </b-row>
    </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>

export default {
  name: "About",
  title: "Feminist Mentoring",
  data() {
    return {
      title: "About"
    }
  },
  mounted: function () {

  },
  methods: {

  }
}
</script>

<style scoped>

.about-text {
  margin: auto;
  padding-top: 30px;
  font-size: 22px;
}

.about-image {
  text-align: center;
}

.image-border {
  border-radius: 30px;
  display: block;

  background-color: rgba(0, 0, 0, 0.72);
  border: 2px solid #012A36;
  margin: auto;
}

.fm-col{
  background-color: rgba(1, 42, 54, 0.11);
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
}

.blockquote {
  background-color: rgba(222, 107, 172, 0.24);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  text-align: center;
}



.page-header {
  background-color: rgb(1, 42, 54);
  width: fit-content;
  color: #e66eb2;
  margin: 30px 0px 30px 30px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 25px;
  background-image: url('/static/images/about-header.jpg');
}




.image_header {
  height: 300px;
  background-position: right bottom;
  border-bottom:5px solid #022b36;
  background: linear-gradient(rgba(2, 43, 54, 0.1), rgba(2, 43, 54, 0.1)), url('/static/images/about-header.jpg') no-repeat right;
  background-size: cover;
}



.card-header {
  background-color: #d568a57d;
}



</style>
