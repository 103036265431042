<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <b-container fluid="xl">
      <div class="contact-us-box">
      <b-card
          img-src="/static/images/contact-header.jpg"
          img-top>
        <template #header>
          <h4 class="mb-0">
            <b-icon icon="envelope" style="width: 20px; height: 20px;"></b-icon>
            Contact Us
          </h4>
        </template>
        <b-card-text v-if="show">
          <p>To discuss the services we offer or for any other queries, please reach out to us through
          the below contact form.</p>
          <b-form @submit="onSubmit" v-if="show">

            <b-form-group id="input-group-2" label="Your Name" label-for="input-2">
              <b-form-input
                  id="input-2"
                  v-model="form.name"
                  placeholder="Enter name"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-1"
                label="Email address"
                label-for="input-1"
            >
              <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter email"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-1"
                label="Organisation"
                label-for="input-1"
            >
              <b-form-input
                  id="input-1"
                  v-model="form.org"
                  placeholder="Enter Organisation or Company name if relevant"
              ></b-form-input>
            </b-form-group>


            <b-form-group id="input-group-3" label="Message" label-for="contact-us-text" description="Please do not include personal or sensitive information in your message. Max 700 characters.">
              <b-form-textarea
                  id="contact-us-text"
                  placeholder="Your message to us"
                  v-model="form.message"
                  :state="form.message.length <= 700 && form.message.length >= 20"
                  rows="6"
              ></b-form-textarea>
            </b-form-group>
            <br>
            <b-button type="submit" variant="fm">Submit</b-button>
          </b-form>
        </b-card-text>
        <b-card-text v-if="!show">
          <p>Thanks for your message, we'll be in touch to discuss</p>
        </b-card-text>

      </b-card>

    </div>
    </b-container>
  </b-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import api from "../api.js";

export default {
  name: "Contact",
  title: "Beki Osborne - Contact Us",
  data() {
    return {
      form: {
        email: '',
        name: '',
        message: '',
        org: ''
      },
      show: true,
    }
  },
  mounted: function () {

  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      let formData = new FormData();
      formData.append('name', this.form.name);
      formData.append('email', this.form.email);
      formData.append('org', this.form.org);
      formData.append('message', this.form.message);
      api.post("contact/", formData,{})
          .then((response) => {
            console.log("Submitting");
            console.log(response.data);
            this.show = false;
          })
    },

  },
}
</script>

<style scoped>
.contact-us-box {
  padding-top: 30px;
}

.container-fluid {
  max-width: 70vw;
  min-width: 70vw;
}

input {
  margin-bottom: 20px;
}

</style>
