<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">

    <b-container fluid="xl">
      <div class="login-box">
        <b-card>
          <template #header>
            <h4 class="mb-0">
              Feminist Mentoring - Login
            </h4>
          </template>
          <b-card-text>
            <form>
            <b-form-group
                id="input-group-1"
                label="Email Address"
                label-for="input-1"
                class="pb-3 w-75"
            >
              <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter email"
                  class="w-100"
                  required
              ></b-form-input>
            </b-form-group>
            <label for="text-password">Password</label>
            <b-form-input type="password" id="text-password" aria-describedby="password-help-block" v-model="form.password" class="w-75 mb-3"></b-form-input>
            <template>
              <b-link class="fm-link" to="forgotpassword">Forgotten your password?</b-link>
              <br><b-button size="md" variant="success" @click="login" type="submit" class="m-2">
                Login
              </b-button>
<!--              <b-button size="md" variant="danger" @click="cancel()">-->
<!--                Cancel-->
<!--              </b-button>-->
            </template><br>
            </form>
          </b-card-text>

        </b-card>

      </div>


      <b-row class="pt-4 mt-4">




        </b-row>


      </b-container>

  </b-container>
</template>

<script>

import {logout} from "@/auth";
import api from "@/api";
import jwt_decode from "jwt-decode";

export default {
  name: "Home",
  data() {
    return {
      users: [],
      form: {
        email: '',
        password: ''
      }
    }
  },
  metaInfo: function () {
    return {
      title: "Feminist Mentoring Login",
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: "Login page for the Feminist Mentoring platform"},
        { name: 'og:site_name', content: "Beki Osborne - Feminist Mentoring"},
        { name: 'og:title', content: "Beki Osborne - Feminist Mentoring Login"},
        { name: 'og:url', content: "https://www.bekiosborne.com/"},
        { name: 'og:type', content: "website"}
      ]
    }
  },
  methods: {
    logout,
    login(evt) {
      evt.preventDefault()
      const payload = {
        email: this.form.email,
        password: this.form.password
      }
      api.post(this.$store.state.endpoints.obtainJWT, payload)
          .then((response) => {
            // this.$cookies.set('token', response.data.token);
            const decoded = jwt_decode(response.data.access)
            this.$store.commit("setAuthUser",
                {
                  authUser: decoded.name,
                  isAdmin: decoded.isAdmin,
                  isAuthenticated: true,
                  isMentee: decoded.isMentee,
                  isMentor: decoded.isMentor,
                  access: response.data.access,
                  refresh: response.data.refresh
                })
          })
          .then(() => {
            if (this.$store.state.isMentee) {
              this.$router.replace(this.$route.query.from);
              // this.$router.push({ name: 'MenteeHub' })
            }
            else if (this.$store.state.isMentor) {
              this.$router.replace(this.$route.query.from);
              // this.$router.push({path: '/mentorhub'})
            }
            else if (this.$store.state.isAdmin) {
              this.$router.replace(this.$route.query.from);
              // this.$router.push({path: '/admin'})
            }
            else {
              this.$router.push({path: '/'})
            }
          })
          .catch(() => {
            this.$parent.makeToast("Invalid username or password");
      });

    },
  }
}
</script>

<style scoped>

.login-box {
  padding-top: 30px;
  width: 80%;
  margin: auto;
}

.login-box .card-header {
  color: #f5f5f5;
  background-color: #022b36;
}

</style>
