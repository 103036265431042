<template lang="html">
  <b-container fluid="sm">
    <br>
    <h1>Create an Athena Mentoring account</h1>
    <h6>This page will become a guided registration page, you complete the first section and then based on selections
      for mentor or mentee different questions will appear</h6>
    <div class="accordion" role="tablist">
      <b-card no-body
              tag="register"
              style="max-width: 80%;"
              class="mx-auto login"
              align="center"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 variant="primary" class="reg-button" size="sm">Account Details
          </b-button>
        </b-card-header>
        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
          <b-card-body class="reg-background">
            <b-card-text>
              <b-form @submit="onSubmit">
                <b-row>

                  <b-col cols="12">
                    <b-form-group
                        id="input-group-2"
                        label="Email address"
                        label-for="input-2"
                    >
                      <b-form-input
                          id="input-2"
                          v-model="form.email"
                          type="email"
                          required
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pt-3">
                  <b-col cols="6">

                    <b-form-group id="input-group-3" label="First name" label-for="input-3">
                      <b-form-input
                          id="input-3"
                          v-model="form.first_name"
                          type="fname"
                          required
                          placeholder="First name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group id="input-group-4" label="Last name" label-for="input-4">
                      <b-form-input
                          id="input-4"
                          v-model="form.last_name"
                          type="lname"
                          required
                          placeholder="Last name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="pt-3">
                  <b-col>
                    <b-form-group
                        id="input-group-5"
                        label="Password"
                        label-for="input-5"
                    >
                      <b-form-input
                          id="input-5"
                          v-model="form.password"
                          type="password"
                          required
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="pt-2">
                  <b-col>
                    <b-form-group
                        id="input-group-6"
                        label="Verify Password"
                        label-for="input-6"
                    >
                      <b-form-input
                          id="input-6"
                          v-model="form.password2"
                          type="password"
                          required
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-form>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>


      <b-card no-body
              tag="register"
              style="max-width: 80%;"
              class="mx-auto login"
              align="center"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-2 variant="primary" class="reg-button" size="sm">About You</b-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body class="reg-background">
            <b-card-text>
              <b-form @submit="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                        id="input-group-1"
                        label="Job Title"
                        label-for="input-1"
                    >
                      <b-form-input
                          id="input-1"
                          v-model="form.jobTitle"
                          type="text"
                          required
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pt-3">
                  <b-col cols="12">
                    <b-form-group
                        id="input-group-2"
                        label="Bio"
                        label-for="input-2"
                    >
                      <b-form-textarea
                          id="input-2"
                          v-model="form.bio"
                          type="text"
                          required
                          placeholder=""
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pt-3">
                  <b-col cols="6">

                    <b-form-group label="Do you wish to be a mentor, a mentee or both?" v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group
                          id="checkbox-group-2"
                          v-model="form.userType"
                          :aria-describedby="ariaDescribedby"
                          name="flavour-2"
                      >
                        <b-form-checkbox value="mentor">Mentor</b-form-checkbox>
                        <b-form-checkbox value="mentee">Mentee</b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>

                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Private Account?" v-slot="{ ariaDescribedby }">
                      <b-form-radio v-model="form.private" :aria-describedby="ariaDescribedby" name="some-radios"
                                    value="yes">Yes
                      </b-form-radio>
                      <b-form-radio v-model="form.private" :aria-describedby="ariaDescribedby" name="some-radios"
                                    value="no">No
                      </b-form-radio>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="pt-3">
                  <b-col>
                    <b-form-group
                        id="input-group-5"
                        label="Any Accessibility needs?"
                        label-for="input-5"
                    >
                      <b-form-input
                          id="input-5"
                          v-model="form.accessibility"
                          type="text"
                          required
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="pt-2">
                  <b-col>
                    <b-form-group
                        id="input-group-6"
                        label="Other"
                        label-for="input-6"
                    >
                      <b-form-input
                          id="input-6"
                          v-model="form.accessibilityother"
                          type="text"
                          required
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-form>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body
              tag="register"
              style="max-width: 80%;"
              class="mx-auto login"
              align="center"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-3 variant="primary" class="reg-button" size="sm">Mentee Registration
          </b-button>
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body class="reg-background">
            <b-card-text>
              <b-form @submit="onSubmit">
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Have you been Mentored before?" v-slot="{ ariaDescribedby }">
                      <b-form-radio v-model="form.mentee_mentoredBefore" :aria-describedby="ariaDescribedby"
                                    name="some-radios" value="yes">Yes
                      </b-form-radio>
                      <b-form-radio v-model="form.mentee_mentoredBefore" :aria-describedby="ariaDescribedby"
                                    name="some-radios" value="no">No
                      </b-form-radio>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                        id="input-group-1"
                        label="Core Experience (tag multi-select)"
                        label-for="input-1"
                    >
                      <b-form-input
                          id="input-1"
                          v-model="form.mentee_experience"
                          type="text"
                          required
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row class="pt-3">
                  <b-col cols="12">
                    <b-form-group
                        id="input-group-2"
                        label="First Objective or Goal"
                        label-for="input-2"
                    >
                      <b-form-input
                          id="input-2"
                          v-model="form.mentee_objective_one"
                          type="email"
                          required
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pt-3">
                  <b-col cols="12">
                    <b-form-group
                        id="input-group-2"
                        label="Second Objective or Goal"
                        label-for="input-2"
                    >
                      <b-form-input
                          id="input-2"
                          v-model="form.mentee_objective_two"
                          type="email"
                          required
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pt-3">
                  <b-col cols="12">
                    <b-form-group
                        id="input-group-2"
                        label="Third Objective or Goal"
                        label-for="input-2"
                    >
                      <b-form-input
                          id="input-2"
                          v-model="form.mentee_objective_three"
                          type="email"
                          required
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="pt-3 pb-3">
                  <b-col>
                    <b-form-group
                        id="input-group-5"
                        label="What is your motiviation for being mentored?"
                        label-for="input-5"
                    >
                      <b-form-textarea
                          id="input-5"
                          v-model="form.mentee_motivation"
                          type="text"
                          required
                          placeholder=""
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>


                <b-button type="submit" variant="primary" size="sm">Next</b-button>
              </b-form>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body
              tag="register"
              style="max-width: 80%;"
              class="mx-auto login"
              align="center"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-4 variant="primary" class="reg-button" size="sm">Mentor Registration
          </b-button>
        </b-card-header>
        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
          <b-card-body class="reg-background">
            <b-card-text>
              <b-form @submit="onSubmit">
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Have you Mentored someone before?" v-slot="{ ariaDescribedby }">
                      <b-form-radio v-model="form.mentor_mentoredBefore" :aria-describedby="ariaDescribedby"
                                    name="some-radios" value="yes">Yes
                      </b-form-radio>
                      <b-form-radio v-model="form.mentor_mentoredBefore" :aria-describedby="ariaDescribedby"
                                    name="some-radios" value="no">No
                      </b-form-radio>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                        id="input-group-1"
                        label="Core Experience (tag multi-select)"
                        label-for="input-1"
                    >
                      <b-form-input
                          id="input-1"
                          v-model="form.mentor_experience"
                          type="text"
                          required
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row class="pt-3 pb-3">
                  <b-col>
                    <b-form-group
                        id="input-group-5"
                        label="What is your motiviation to Mentor someone?"
                        label-for="input-5"
                    >
                      <b-form-textarea
                          id="input-5"
                          v-model="form.mentor_motivation"
                          type="text"
                          required
                          placeholder=""
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>


                <b-button type="submit" variant="primary" size="sm">Next</b-button>
              </b-form>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>


<!--      <b-card no-body-->
<!--              tag="register"-->
<!--              style="max-width: 80%;"-->
<!--              class="mx-auto login"-->
<!--              align="center"-->
<!--      >-->
<!--        <b-card-header header-tag="header" class="p-1" role="tab">-->
<!--          <b-button block v-b-toggle.accordion-5 variant="primary" class="reg-button" size="sm">Equality Information-->
<!--          </b-button>-->
<!--        </b-card-header>-->
<!--        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">-->
<!--          <b-card-body class="reg-background">-->
<!--            <b-card-text>-->
<!--              <b-form @submit="onSubmit">-->
<!--                <b-row>-->
<!--                  <h6>The questions in this section are optional, the answers will be used to stuff (better wording to-->
<!--                    come)</h6>-->
<!--                </b-row>-->
<!--                <b-row>-->
<!--                  <b-col cols="6">-->
<!--                    <b-form-group label="Gender?">-->
<!--                      <b-form-input-->
<!--                          id="input-1"-->
<!--                          v-model="form.equalities.gender"-->
<!--                          type="text"-->
<!--                          required-->
<!--                          placeholder=""-->
<!--                      ></b-form-input>-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                  <b-col cols="6">-->
<!--                    <b-form-group label="Is the gender you identify with, the same as your gender assigned at birth?"-->
<!--                                  v-slot="{ ariaDescribedby }">-->
<!--                      <b-form-radio v-model="form.equalities.genderAtBirth" :aria-describedby="ariaDescribedby"-->
<!--                                    name="some-radios" value="yes">Yes-->
<!--                      </b-form-radio>-->
<!--                      <b-form-radio v-model="form.equalities.genderAtBirth" :aria-describedby="ariaDescribedby"-->
<!--                                    name="some-radios" value="no">No-->
<!--                      </b-form-radio>-->
<!--                      <b-form-radio v-model="form.equalities.genderAtBirth" :aria-describedby="ariaDescribedby"-->
<!--                                    name="some-radios" value="abs">Prefer not to say-->
<!--                      </b-form-radio>-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                </b-row>-->

<!--                <b-row class="pt-3 pb-3">-->
<!--                  <b-col>-->
<!--                    <b-form-group-->
<!--                        id="input-group-5"-->
<!--                        label="What is your age range?"-->
<!--                        label-for="input-5"-->
<!--                    >-->
<!--                      <b-form-select v-model="form.equalities.ageRange" class="mb-3">-->
<!--                        <b-form-select-option :value="null">Please select an option</b-form-select-option>-->
<!--                        <b-form-select-option value="18-24">18-24</b-form-select-option>-->
<!--                        <b-form-select-option value="25-34">25-34</b-form-select-option>-->
<!--                        <b-form-select-option value="35-44">35-44</b-form-select-option>-->
<!--                        <b-form-select-option value="45-54">45-54</b-form-select-option>-->
<!--                        <b-form-select-option value="55-64">55-64</b-form-select-option>-->
<!--                        <b-form-select-option value="65+">65+</b-form-select-option>-->
<!--                      </b-form-select>-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                  <b-col>-->
<!--                    <b-form-group-->
<!--                        id="input-group-5"-->
<!--                        label="What is your ethnicity? Please make a selection you feel comfortable identifying with"-->
<!--                        label-for="input-5"-->
<!--                    >-->
<!--                      <b-form-select v-model="form.equalities.ethnicity" class="mb-3">-->
<!--                        <b-form-select-option :value="null">Please select an option</b-form-select-option>-->
<!--                        <b-form-select-option value="Asian or Asian British">Asian or Asian British</b-form-select-option>-->
<!--                        <b-form-select-option value="Black, African, Caribbean or Black British">Black, African, Caribbean or Black British</b-form-select-option>-->
<!--                        <b-form-select-option value="Mixed or multiple ethnic groups">Mixed or multiple ethnic groups</b-form-select-option>-->
<!--                        <b-form-select-option value="White">White</b-form-select-option>-->
<!--                        <b-form-select-option value="Other">Other...</b-form-select-option>-->
<!--                      </b-form-select>-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                </b-row>-->
<!--<b-row  class="pt-3 pb-3">-->
<!--  <b-col>-->
<!--    <b-form-group label="Do you consider yourself to have a disability?"-->
<!--                  v-slot="{ ariaDescribedby }">-->
<!--      <b-form-radio v-model="form.equalities.disabled" :aria-describedby="ariaDescribedby"-->
<!--                    name="some-radios" value="yes">Yes-->
<!--      </b-form-radio>-->
<!--      <b-form-radio v-model="form.equalities.disabled" :aria-describedby="ariaDescribedby"-->
<!--                    name="some-radios" value="no">No-->
<!--      </b-form-radio>-->
<!--      <b-form-radio v-model="form.equalities.disabled" :aria-describedby="ariaDescribedby"-->
<!--                    name="some-radios" value="abs">Prefer not to say-->
<!--      </b-form-radio>-->
<!--    </b-form-group>-->
<!--  </b-col>-->
<!--  <b-col>-->
<!--    <b-form-group label="Do you identify at LGBTQ+?"-->
<!--                  v-slot="{ ariaDescribedby }">-->
<!--      <b-form-radio v-model="form.equalities.lgbtq" :aria-describedby="ariaDescribedby"-->
<!--                    name="some-radios" value="yes">Yes-->
<!--      </b-form-radio>-->
<!--      <b-form-radio v-model="form.equalities.lgbtq" :aria-describedby="ariaDescribedby"-->
<!--                    name="some-radios" value="no">No-->
<!--      </b-form-radio>-->
<!--      <b-form-radio v-model="form.equalities.lgbtq" :aria-describedby="ariaDescribedby"-->
<!--                    name="some-radios" value="abs">Prefer not to say-->
<!--      </b-form-radio>-->
<!--    </b-form-group>-->
<!--  </b-col>-->
<!--</b-row>-->
<!--                <b-row class="pt-3 pb-3">-->
<!--                  <b-col>-->
<!--                    <b-form-group-->
<!--                        id="input-group-5"-->
<!--                        label="What is your religion or belief?"-->
<!--                        label-for="input-5"-->
<!--                    >-->
<!--                      <b-form-select v-model="form.equalities.beliefs" class="mb-3">-->
<!--                        <b-form-select-option :value="null">Please select an option</b-form-select-option>-->
<!--                        <b-form-select-option value="Buddhist">Buddhist</b-form-select-option>-->
<!--                        <b-form-select-option value="Christian">Christian</b-form-select-option>-->
<!--                        <b-form-select-option value="Hindu">Hindu</b-form-select-option>-->
<!--                        <b-form-select-option value="Jewish">Jewish</b-form-select-option>-->
<!--                        <b-form-select-option value="Muslim">Muslim</b-form-select-option>-->
<!--                        <b-form-select-option value="Sikh">Sikh</b-form-select-option>-->
<!--                        <b-form-select-option value="No religion or belief">No religion or belief</b-form-select-option>-->
<!--                        <b-form-select-option value="Prefer not to say">Prefer not to say</b-form-select-option>-->
<!--                        <b-form-select-option value="Other">Other...</b-form-select-option>-->
<!--                      </b-form-select>-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                  <b-col>-->
<!--                    <b-form-group-->
<!--                        id="input-group-5"-->
<!--                        label="Do you have caring responsibilities?"-->
<!--                        label-for="input-5"-->
<!--                    >-->
<!--                      <b-form-select v-model="form.equalities.caringResponsibilities" class="mb-3">-->
<!--                        <b-form-select-option :value="null">Please select an option</b-form-select-option>-->
<!--                        <b-form-select-option value="Primary carer of a child/children (under 18)">Primary carer of a child/children (under 18)</b-form-select-option>-->
<!--                        <b-form-select-option value="Primary carer of disabled child/children (under 18)">Primary carer of disabled child/children (under 18)</b-form-select-option>-->
<!--                        <b-form-select-option value="Primary carer of disabled adult (18 and over)">Primary carer of disabled adult (18 and over)</b-form-select-option>-->
<!--                        <b-form-select-option value="Primary carer of older person">Primary carer of older person</b-form-select-option>-->
<!--                        <b-form-select-option value="Secondary carer (another person carries out the main caring role)">Secondary carer (another person carries out the main caring role)</b-form-select-option>-->
<!--                        <b-form-select-option value="None">None</b-form-select-option>-->
<!--                        <b-form-select-option value="Prefer not to say">Prefer not to say</b-form-select-option>-->
<!--                        <b-form-select-option value="Other">Other...</b-form-select-option>-->
<!--                      </b-form-select>-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                </b-row>-->
<!--                <b-row>-->
<!--                  <b-col>-->
<!--                    <b-form-group-->
<!--                        id="input-group-5"-->
<!--                        label="What is the highest level of qualification you hold?"-->
<!--                        label-for="input-5"-->
<!--                    >-->
<!--                      <b-form-select v-model="form.equalities.highestQualification" class="mb-3">-->
<!--                        <b-form-select-option :value="null">Please select an option</b-form-select-option>-->
<!--                        <b-form-select-option value="Doctorate/level 8 qualification">Doctorate/level 8 qualification</b-form-select-option>-->
<!--                        <b-form-select-option value="Masters/level 7 qualification">Masters/level 7 qualification</b-form-select-option>-->
<!--                        <b-form-select-option value="Degree /level 6 qualification">Degree /level 6 qualification</b-form-select-option>-->
<!--                        <b-form-select-option value="Foundation degree/level 5 qualification">Foundation degree/level 5 qualification</b-form-select-option>-->
<!--                        <b-form-select-option value="Certificate of higher education/higher apprenticeship/level 4 qualification">Certificate of higher education/higher apprenticeship/level 4 qualification</b-form-select-option>-->
<!--                        <b-form-select-option value="A level/advanced apprenticeship/level 3 qualification">A level/advanced apprenticeship/level 3 qualification</b-form-select-option>-->
<!--                        <b-form-select-option value="GCSE/apprenticeship/level 1 or 2 qualification">GCSE/apprenticeship/level 1 or 2 qualification</b-form-select-option>-->
<!--                        <b-form-select-option value="None">None</b-form-select-option>-->
<!--                        <b-form-select-option value="Prefer not to say">Prefer not to say</b-form-select-option>-->
<!--                        <b-form-select-option value="Other">Other...</b-form-select-option>-->
<!--                      </b-form-select>-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                </b-row>-->
<!--                <b-button type="submit" variant="primary" size="sm">Next</b-button>-->
<!--              </b-form>-->
<!--            </b-card-text>-->
<!--          </b-card-body>-->
<!--        </b-collapse>-->
<!--      </b-card>-->


    </div>
  </b-container>
</template>

<script>
import api from "../api.js";

export default {
  data() {
    return {
      form: {
        password: '',
        password2: '',
        email: '',
        first_name: '',
        last_name: '',
        mentee_mentoredBefore: '',
        mentee_motivation: '',
        mentee_objective_one: '',
        mentee_objective_two: '',
        mentee_objective_three: '',
        mentee_experience: '',
        mentor_mentoredBefore: '',
        mentor_experience: '',
        mentor_motivation: '',
        jobTitle: '',
        bio: '',
        userType: [],
        accessibility: '',
        accessibilityother: ''
        // equalities: {
        //   gender: '',
        //   genderAtBirth: '',
        //   ageRange: '',
        //   ethnicity: '',
        //   disabled: '',
        //   lgbtq: '',
        //   beliefs: '',
        //   caringResponsibilities: '',
        //   highestQualification: ''
        // }
      }
    }
  },
  methods: {
    onSubmit(evt) {
      if (evt) {
        evt.preventDefault()
      }
      // eslint-disable-next-line no-unused-vars
      let formData = new FormData();
      // formData.append('username', this.form.username);
      // formData.append('password', this.form.password);
      // formData.append('password2', this.form.password2);
      // formData.append('email', this.form.email);
      // formData.append('first_name', this.form.first_name);
      // formData.append('last_name', this.form.last_name);
      // formData.append('data', this.form);
      Object.entries(this.form).forEach(([key, value]) => {
        formData.append(key, value);
      });
      api.post("register/", formData)
          .then((response) => {
            console.log("Submitting");
            console.log(response.data);
          })
    }
  }
}
</script>

<style lang="css" scoped>
.reg-button {
  width: 100%;
}

.reg-background {
  background-color: rgba(33, 37, 41, 0.28);
}
</style>